<template>
    <div>
        <ContextTitle :passedActions="titleBarActions" title="Styles" />
        <v-container fluid>
            <v-card width="100%" class="pa-3">
                <v-row class="mt-3">
                    <v-col md="8" class="pt-0 pb-0">
                        <store-search-new
                            :airport="airport"
                            :waypoint="waypointId"
                            @airport-change="airport = $event"
                            @waypoint-change="onLocationChange"
                        />
                    </v-col>
                </v-row>
                <v-row no-gutters class="pt-0 mt-4">
                    <v-col md="3" class="pr-3 pl-1">
                        <v-text-field
                            v-model="searchString"
                            attach
                            label="Search Styles"
                            clearable
                        />
                    </v-col>
                    <!-- <v-col md="3" class="pr-3 pl-1">
                        <v-select
                            v-model="selectedPlatformTypes"
                            :items="platformTypeOptions"
                            item-value="id"
                            item-text="text"
                            multiple
                            attach
                            label="Platforms"
                            clearable
                        />
                    </v-col> -->
                </v-row>
            </v-card>
            <v-row no-gutters>
                <v-col class="fill-height pr-3">
                    <v-row justify="center" class="overflow-y-auto mt-5">
                        <v-col>
                            <h5 v-if="!isWaypointsSelected" class="pt-3">
                                Select a waypoint to start
                            </h5>

                            <h5
                                v-else-if="filteredFields.length === 0"
                                class="pt-3"
                            >
                                Sorry, no style items matches your query.
                            </h5>
                        </v-col>
                    </v-row>
                    <v-row
                        no-gutters
                        class="mt-0 mb-3"
                        v-if="
                            waypointId != undefined
                        "
                    >
                        <v-col lg="9" md="10" sm="12" class="fill-height">
                            <v-card>
                                <v-card-title><v-row no-gutters>
                                    <div class="mr-3">Prod</div>
                                    <div>Stage</div></v-row></v-card-title>
                                <v-card-text>
                                    <StylesForm
                                        :loading="
                                            isMetadataLoading ||
                                            isStylesLoading ||
                                            isSaveInProgress
                                        "
                                        v-model="stageStyles"
                                        :metadata="metadata"
                                        :fields="filteredFields"
                                        :searchString="searchString"
                                        :selectedPlatformTypes="
                                            selectedPlatformTypes
                                        "
                                        :prodValue="prodStyles"
                                        env="stage"
                                    />
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <!-- <v-col md="4" class="fill-height ml-2">
                            <v-card>
                                <v-card-title>Prod</v-card-title>
                                <v-card-text>
                                    <ConfigForm
                                        :loading="
                                            isMetadataLoading ||
                                            isConfigLoading ||
                                            isSaveInProgress
                                        "
                                        v-model="configs.prod"
                                        :metadata="metadata"
                                        :fields="filteredFields"
                                        :searchString="searchString"
                                        :selectedPlatformTypes="
                                            selectedPlatformTypes
                                        "
                                        env="prod"
                                    />
                                </v-card-text>
                            </v-card>
                        </v-col> -->
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import ContextTitle from "components/context_title.vue";
import StoreSearchNew from "components/store_search_new";
import FieldRow from "components/create_store/fields/FieldRow";
import TextTooltip from "components/text_tooltip";
import StylesForm from "components/styles_form";
import clone from "clone";

import { mapGetters, mapActions } from "vuex";

export default {
    name: "StylesIndex",
    components: {
        ContextTitle,
        StoreSearchNew,
        FieldRow,
        TextTooltip,
        StylesForm,
    },
    data() {
        return {
            selectedPlatformTypes: [],
            airport: null,
            waypointId: null,
            searchString: "",
            isMetadataLoading: false,
            isStylesLoading: false,
            isSaveInProgress: false,
            stageStyles: undefined,
            prodStyles: undefined
        };
    },
    computed: {
        titleBarActions() {
            if (this.isWaypointsSelected && !this.isStylesLoading) {
                return [
                    {
                        type: "proceed",
                        display: "Save",
                        run: () => {
                            this.save();
                        },
                    },
                    {
                        type: "duplicate",
                        display: "Publish To Prod",
                        run: () => {
                            this.publish();
                        },
                    },
                ];
            } else return [];
        },
        filteredFields() {
            if (this.metadata == undefined || this.metadata.fields == undefined)
                return [];
            let fields = this.metadata.fields;
            if (this.searchString !== "") {
                fields = fields.filter((el) =>
                    el.title
                        .toLowerCase()
                        .includes(this.searchString.toLowerCase())
                );
            }
            if (this.selectedPlatformTypes.length > 0) {
                fields = fields.filter((el) => {
                    for (const p of this.selectedPlatformTypes) {
                        if (el.platforms.includes(parseInt(p))) {
                            return true;
                        }
                    }
                    return false;
                });
            }
            return fields;
        },
        platformTypeOptions() {
            if (
                this.styleMetadata == undefined ||
                this.styleMetadata.platformMap == undefined
            )
                return [];
            return Object.entries(this.styleMetadata.platformMap).map(
                ([key, value]) => {
                    return {
                        id: key,
                        text: value,
                    };
                }
            );
        },
        metadata() {
            return this.styleMetadata;
        },
        isWaypointsSelected() {
            return !!this.waypointId;
        },
        ...mapGetters("ConfigStore", [
            "styleMetadata",
            "bandaidStyleData",
            "styles",
            "configStoreWaypointID",
        ]),
    },
    mounted() {
        this.getMetadata();
        this.waypointId = this.configStoreWaypointID;
        if (Object.keys(this.styles).length > 1) {
            this.stageStyles = clone(this.styles.stage);
            this.prodStyles = clone(this.styles.prod)
        } else if (this.waypointId) {
            this.onLocationChange(this.waypointId)
        }
    },
    methods: {
        onLocationChange(waypointId) {
            this.waypointId = waypointId;
            this.getStyle(this.waypointId);
        },
        getMetadata() {
            this.isMetadataLoading = true;
            this.getStyleMetadata(this.waypointId).finally(() => {
                this.isMetadataLoading = false;
            });
        },
        getStyle(waypointId) {
            if (!waypointId) {
                return;
            }
            this.isStylesLoading = true;
            this.getStyles(waypointId).finally(
                () => (this.isStylesLoading = false)
            );
        },

        save() {
            var result = [];
            for (const field of this.bandaidStyleData) {
                if (this.stageStyles[field.key] == undefined) continue;
                if (
                    !this.compareStyleValue(
                        this.stageStyles[field.key],
                        field.original.DefaultValue
                    )
                ) {
                    result.push({
                        StyleID: field.original.StyleID,
                        HasOverride: true,
                        StyleValue: this.stageStyles[field.key],
                    });
                }
            }
            if (result.length == 0) {
                this.toast("No changes to save.");
                return;
            }

            this.isSaveInProgress = true;
            this.saveStyles({
                storeWaypointID: this.waypointId,
                bandaid: result,
                styles: this.stageStyles
            }).finally(() => {
                this.isSaveInProgress = false;
            });
        },
        publish() {
            this.isSaveInProgress = true;
            this.publishStyles(this.waypointId).finally(
                () => (this.isSaveInProgress = false)
            );
        },
        compareStyleValue(parsed, original) {
            if (original == "transparent")
                return parsed == "#FFFFFF00" || parsed == "rgba(255,255,255,0)";
            if (original == "white" || original == "#fff")
                return parsed == "#ffffff" || parsed == "#fff";
            if (original == "black" || original == "#000")
                return (parsed = "#000000" || parsed == "#000");
            if (original == "#555") return parsed == "#555555";
            return (
                parsed.toLowerCase().replaceAll(" ", "") ==
                original.toLowerCase().replaceAll(" ", "")
            );
        },
        ...mapActions("ConfigStore", [
            "getStyles",
            "getStyleMetadata",
            "saveStyles",
            "publishStyles",
        ]),
    },
    watch: {
        styles() {
            this.stageStyles = clone(this.styles.stage);
            this.prodStyles = clone(this.styles.prod)
        },
    },
};
</script>

<style>
.color-input div.v-input__slot > div:nth-child(1) > div > div:nth-child(1) {
    border: 1px solid gray;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
</style>
