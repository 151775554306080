

<template>
    <div>
        <div v-if="loading || value == undefined || fields == undefined">
            <v-skeleton-loader
                v-for="i in 20"
                :key="i"
                type="list-item"
                class="ma-2 d-flex flex-column"
                elevation="2"
                width="100%"
                height="60px"
            />
        </div>
        <div v-else>
            <field-row
                v-for="(field, k) in fields"
                :key="k"
                hide-line-number
                title=""
                class="mb-3 mt-5 pb-1"
            >
                <v-row no-gutters v-if="value[field.key]">
                    <div class="mr-5">
                        <v-color-input
                            v-if="field.type.toLowerCase() === 'color'"
                            v-model="prodValue[field.key]"
                            disabled
                            hide-inputs
                            hide-canvas
                            elevation="2"
                            class="color-input"
                        >
                        </v-color-input>
                    </div>
                    <v-col md="5" sm="5">
                        <v-color-input
                            v-if="field.type.toLowerCase() === 'color'"
                            :label="field.title"
                            v-model="value[field.key]"
                            :mode="
                                value[field.key].includes('rgba')
                                    ? 'rgba'
                                    : 'hexa'
                            "
                            elevation="2"
                            :hint="field.doc"
                            persistentHint
                            class="color-input"
                        >
                        </v-color-input>
                    </v-col>
                    <v-col md="6">
                        <ColorField
                            v-if="field.type.toLowerCase() === 'color'"
                            v-model="value[field.key]"
                            :id="field.key"
                            :doc="field.doc"
                        />
                    </v-col>
                </v-row>
                <v-divider light class="mb-1" />
            </field-row>
        </div>
    </div>
</template>

<script>
import _ from "lodash";
import RadioGroupRow from "components/create_store/fields/RadioGroupRow";
import FieldRow from "components/create_store/fields/FieldRow";
import TextTooltip from "components/text_tooltip";
import ColorField from "components/form/ColorField.vue";

export default {
    name: "StylesForm",
    data() {
        return {};
    },
    props: {
        env: {
            type: String,
            required: true,
        },
        metadata: {
            type: Object,
            required: true,
        },
        value: {
            type: Object,
        },
        loading: {
            type: Boolean,
            required: true,
        },
        searchString: {
            type: String,
            required: true,
        },
        selectedPlatformTypes: {
            type: Array,
            required: true,
        },
        fields: {
            type: Array,
            required: true,
        },
        prodValue: {
            type: Object,
        },
    },
    components: {
        RadioGroupRow,
        FieldRow,
        TextTooltip,
        ColorField,
    },
    computed: {
        disabled() {
            return this.env == "prod";
        },
    },
    created() {
        //this.debounced_input = _.debounce(this.onSearchChange);
    },
    methods: {
        onFieldChange(val) {
            console.log(val);

            return (val2) => {
                console.log(val2);
            };
        },
    },
    watch: {
        waypointId(value) {},
    },
};
</script>

<style lang="css" scoped>
.my-border {
    border: 2px solid black;
}
</style>
