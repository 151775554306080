<template>
    <v-text-field
        :color="fieldColor()"
        :ref="id"
        v-model="color"
        light
        dense
        :error-messages="errorMessages"
        @blur="debounced_onInput"
        @keyup.native.enter="$refs[id].blur()"
    >
        <template v-slot:append-outer v-if="doc !== '???' && doc">
            <text-tooltip :label="doc" />
        </template>
    </v-text-field>
</template>

<script>
import TextTooltip from "components/text_tooltip";

import chroma from "chroma-js";
import _ from "lodash";

export default {
    name: "ColorField",
    props: ["value", "doc", "id"],
    components: {
        TextTooltip,
    },
    data() {
        return {
            error: false,
            tmp: "",
        };
    },
    created() {
        this.debounced_onInput = _.debounce(this.onInput);
    },
    computed: {
        errorMessages() {
            if (this.error) return ["Please enter a valid color"];
            return [];
        },
        color: {
            get() {
                return this.value;
            },
            set(c) {
                this.tmp = c;
                try {
                    chroma(this.tmp);
                    this.error = false;
                } catch (e) {
                    this.error = true;
                }
            },
        },
    },
    methods: {
        fieldColor() {
            return "#93BD20";
        },
        onInput(val) {
            if (!this.error && this.tmp != "")
                this.$emit("input", this.tmp);

        },
    },
};
</script>

<style>
</style>