var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.loading || _vm.value == undefined || _vm.fields == undefined
      ? _c(
          "div",
          _vm._l(20, function (i) {
            return _c("v-skeleton-loader", {
              key: i,
              staticClass: "ma-2 d-flex flex-column",
              attrs: {
                type: "list-item",
                elevation: "2",
                width: "100%",
                height: "60px",
              },
            })
          }),
          1
        )
      : _c(
          "div",
          _vm._l(_vm.fields, function (field, k) {
            return _c(
              "field-row",
              {
                key: k,
                staticClass: "mb-3 mt-5 pb-1",
                attrs: { "hide-line-number": "", title: "" },
              },
              [
                _vm.value[field.key]
                  ? _c(
                      "v-row",
                      { attrs: { "no-gutters": "" } },
                      [
                        _c(
                          "div",
                          { staticClass: "mr-5" },
                          [
                            field.type.toLowerCase() === "color"
                              ? _c("v-color-input", {
                                  staticClass: "color-input",
                                  attrs: {
                                    disabled: "",
                                    "hide-inputs": "",
                                    "hide-canvas": "",
                                    elevation: "2",
                                  },
                                  model: {
                                    value: _vm.prodValue[field.key],
                                    callback: function ($$v) {
                                      _vm.$set(_vm.prodValue, field.key, $$v)
                                    },
                                    expression: "prodValue[field.key]",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { md: "5", sm: "5" } },
                          [
                            field.type.toLowerCase() === "color"
                              ? _c("v-color-input", {
                                  staticClass: "color-input",
                                  attrs: {
                                    label: field.title,
                                    mode: _vm.value[field.key].includes("rgba")
                                      ? "rgba"
                                      : "hexa",
                                    elevation: "2",
                                    hint: field.doc,
                                    persistentHint: "",
                                  },
                                  model: {
                                    value: _vm.value[field.key],
                                    callback: function ($$v) {
                                      _vm.$set(_vm.value, field.key, $$v)
                                    },
                                    expression: "value[field.key]",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { md: "6" } },
                          [
                            field.type.toLowerCase() === "color"
                              ? _c("ColorField", {
                                  attrs: { id: field.key, doc: field.doc },
                                  model: {
                                    value: _vm.value[field.key],
                                    callback: function ($$v) {
                                      _vm.$set(_vm.value, field.key, $$v)
                                    },
                                    expression: "value[field.key]",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("v-divider", { staticClass: "mb-1", attrs: { light: "" } }),
              ],
              1
            )
          }),
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }