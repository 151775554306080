var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-text-field", {
    ref: _vm.id,
    attrs: {
      color: _vm.fieldColor(),
      light: "",
      dense: "",
      "error-messages": _vm.errorMessages,
    },
    on: { blur: _vm.debounced_onInput },
    nativeOn: {
      keyup: function ($event) {
        if (
          !$event.type.indexOf("key") &&
          _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
        )
          return null
        return _vm.$refs[_vm.id].blur()
      },
    },
    scopedSlots: _vm._u(
      [
        _vm.doc !== "???" && _vm.doc
          ? {
              key: "append-outer",
              fn: function () {
                return [_c("text-tooltip", { attrs: { label: _vm.doc } })]
              },
              proxy: true,
            }
          : null,
      ],
      null,
      true
    ),
    model: {
      value: _vm.color,
      callback: function ($$v) {
        _vm.color = $$v
      },
      expression: "color",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }